/**
 * The purpose of this file is to allow for overriding any of the values
 * within the theme config file with ones appropriate for the environment.
 *
 * While there is a good chance that this will only apply to the apiUrl, as
 * the application grows, we may want to override other variables, this allows
 * for that functionality.
 */
const Env = {
    configUrl: 'https://t27-ridemanager-ex.alcschools.cloud/api/v2/Config',
    validateUrl: 'https://t27cternbridge-externalutility.azurewebsites.net/api/v2/validate',
    applicationInsightsKey: '4446d81e-defd-474d-ac85-104340032cad',
    auth: {
        clientId: "3d0df699-611f-4e65-ab1b-efbcff2cb928", // Web App "Application ID" in B2C Tenant
        authoritySub: "t27cmrm",
        signInFlow: "B2C_1_New_SignIn",
        resetFlow: "B2C_1_New_PasswordReset",
        scope: "https://t27cmrm.onmicrosoft.com/ridemanager/user_impersonation"
    },
    areReportsEnabled: true,
    nodeEnv: 't27-alc'
}

export default Env;